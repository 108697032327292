<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1529_10529" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1529_10529)">
      <path d="M19.1931 13.9793C19.4598 13.9793 19.6759 14.1955 19.6759 14.462V16.2C19.6759 17.7465 18.4223 19 16.876 19H7.79995C6.2535 19 5 17.7464 5 16.2V14.462C5 14.1955 5.21613 13.9793 5.48281 13.9793C5.74939 13.9793 5.96552 14.1955 5.96552 14.462V16.2C5.96552 17.2132 6.78688 18.0345 7.80008 18.0345H16.8761C17.8893 18.0345 18.7106 17.2132 18.7106 16.2V14.462C18.7106 14.1955 18.9268 13.9793 19.1933 13.9793H19.1931ZM12.3378 5C12.5938 5 12.8032 5.19912 12.8196 5.45094L12.8206 5.4827V13.4476L14.9051 11.5024C15.0915 11.3284 15.3791 11.3304 15.5629 11.5015L15.5874 11.5259C15.7613 11.7124 15.7593 12 15.5882 12.1839L15.5638 12.2082L12.6702 14.909C12.6615 14.9173 12.6525 14.9252 12.6432 14.9328C12.6408 14.9348 12.6382 14.9368 12.6358 14.9388C12.6301 14.9432 12.6243 14.9477 12.6183 14.9519C12.6141 14.9548 12.6101 14.9577 12.6059 14.9604C12.6016 14.9632 12.5973 14.966 12.593 14.9688C12.5889 14.9714 12.5847 14.9738 12.5806 14.9763C12.5742 14.9799 12.5677 14.9835 12.5611 14.987L12.5534 14.9909C12.5475 14.9939 12.5415 14.9967 12.5355 14.9995C12.5313 15.0013 12.5271 15.0031 12.5229 15.0049C12.5176 15.0071 12.5122 15.0092 12.5069 15.0112L12.4929 15.0161C12.4887 15.0176 12.4844 15.0189 12.4802 15.0202C12.4743 15.022 12.4684 15.0238 12.4625 15.0253C12.4467 15.0296 12.4305 15.033 12.414 15.0356C12.4099 15.0363 12.4059 15.0368 12.4017 15.0373L12.3697 15.0406L12.3379 15.0416H12.3377C12.3131 15.0416 12.2886 15.0397 12.2642 15.036L12.2415 15.0318C12.0937 15.0619 11.9341 15.0225 11.8154 14.9118L8.91878 12.2082C8.72379 12.0263 8.71326 11.7208 8.89518 11.5259C9.07719 11.331 9.38259 11.3204 9.57758 11.5024L11.855 13.6282V5.48286C11.855 5.21628 12.0713 5.00015 12.3378 5.00015L12.3378 5Z" fill="#1E70BB"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCsv"
}
</script>

<style scoped>

</style>